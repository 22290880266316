<template>
  <input
    type="radio"
    class="cursor-pointer"
    :checked="checked"
    :style="{ width: size + 'px', height: size + 'px' }"
    @click="$emit('toggle')"
  />
</template>
<script>
export default {
  name: 'RadioButton',
  props: {
    checked: {
      type: Boolean,
      default: false,
    },
    size: {
      type: Number,
      default: 25,
    },
  },
}
</script>

<style scoped lang="scss">
input[type='radio'] {
  accent-color: $bb-brand-purple;
}
</style>
